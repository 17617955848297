<template lang="pug">
.template-slider.col-12.my-3(v-if="templates.length" ref="slider")
  .template-slider-header
    .template-slider-title(v-if="title")
      om-heading(h3) {{ title }}
    .template-slider-show-more.ml-auto(v-if="showMore")
      om-link(primary @click="toShowMore" withIconRight small) Show more
  .template-slider-body
    .template-slider-body-swiper(v-swiper="swiperOptions")
      .swiper-wrapper
        .swiper-slide(v-for="template in templates")
          template-frame.swiper-preview-thumbnail(
            allowSsr
            :template="template"
            :dimensions="boxDimensions"
            @observable="onObservable"
            @click.native="showPreview(template)"
            @checkFonts="handleFonts"
            :themeKit="getThemeKitForTemplate(template)"
            :idPostfix="idPostfix"
          )
            template(#fallback)
              template-box(
                :template="template"
                small
                @click.native="showPreview(template)"
                withThemeColors
              )
    .swiper-button-next(tabindex="0" role="button" :id="`swiper-button-next-${sliderId}`")
    .swiper-right-gradient
    .swiper-button-prev(tabindex="0" role="button" :id="`swiper-button-prev-${sliderId}`")
    .swiper-left-gradient
    .swiper-pagination
</template>
<script>
  import { Swiper as SwiperClass, Navigation, Autoplay } from 'swiper/swiper.esm';
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
  import { nanoid } from 'nanoid';
  import 'swiper/swiper-bundle.min.css';
  import TemplateBox from '@/components/Template/Themes/TemplateBox.vue';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';

  SwiperClass.use([Navigation, Autoplay]);
  const SwiperComponent = getAwesomeSwiper(SwiperClass);

  const { directive } = getAwesomeSwiper(SwiperClass);

  export default {
    components: { TemplateBox, TemplateFrame, SwiperComponent },
    directives: {
      swiper: directive,
    },
    mixins: [previewParentMixin, ssrMixin, observableCollectionMixin],
    props: {
      title: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: '',
      },
      showMore: {
        type: Object,
      },
      templates: {
        type: Array,
        default: () => [],
      },
      initialOptions: {
        type: Object,
      },
      slidesPerView: { default: 3.5 },
      slidesPerViewTabletLg: { default: 3.5 },
      slidesPerViewTabletSm: { default: 2.5 },
      slidesPerViewMobile: { default: 1.5 },
      idPostfix: { type: String, default: null },
      themeKit: {
        type: Object,
        default: () => null,
      },
      customThemes: {
        type: Object,
        default: () => null,
      },
    },
    data() {
      return {
        ssrBoxSelector: '.swiper-preview-thumbnail',
        sliderId: nanoid(8),
      };
    },
    computed: {
      swiperOptions() {
        return {
          allowTouchMove: this.isMobile,
          slidesPerView: this.slidesPerViewMobile,
          spaceBetween: 20,
          navigation: {
            nextEl: `#swiper-button-next-${this.sliderId}`,
            prevEl: `#swiper-button-prev-${this.sliderId}`,
          },
          breakpoints: {
            768: {
              slidesPerView: this.slidesPerViewTabletSm,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: this.slidesPerViewTabletLg,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: this.slidesPerView,
              spaceBetween: 20,
            },
          },
        };
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.updateDimensions();
      });
    },
    methods: {
      onObservable(event) {
        if (!this.intersectionRoot) {
          this.intersectionRoot = document.querySelector('.template-preview-modal');
        }
        this.addObservable(event.$el);
      },
      toShowMore() {
        this.$modal.hide('template-preview');
        this.$router.push(this.showMore);
      },
      showPreview(template) {
        const themeKit = this.getThemeKitForTemplate(template);
        const payload = {
          templateId: template._id,
          theme: template.theme,
          themeKit,
        };

        this.$modal.show('template-preview', payload);
        this.$emit('change-template', payload);
      },
      getThemeKitForTemplate(template) {
        if (this.themeKit) return this.themeKit;
        if (!this.customThemes) return undefined;
        const theme = this.customThemes.custom.find(({ themeKit }) => {
          return template?.themeKit?.name === themeKit?.source;
        });
        return theme?.themeKit;
      },
    },
  };
</script>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors.sass'
  .swiper-button-disabled
    & ~ .swiper-right-gradient,
    & ~ .swiper-left-gradient
      opacity :0
  .template-slider
    &-header
      display: flex
      align-items: center
    .swiper
      &-right-gradient,
      &-left-gradient
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%)
        width: 2rem
        height: 100%
        position: absolute
        top: 0
        right: 0
        z-index: 9
      &-left-gradient
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%)
        left: 0
    .swiper-slide
      cursor: pointer
      max-width: 100%
    .swiper-container
      padding: 16px 0 40px
      width: 100%!important
    .swiper-button-next, .swiper-button-prev
      width: 2.75rem
      height: 2.75rem
      background: white
      border-radius: 100%
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
      &:after
        color: $om-gray-700
        font-size: 1rem
      &.swiper-button-disabled
        opacity: 0
</style>
